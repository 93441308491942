import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import BackgroundImg from "../components/background.js"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    // <BackgroundImg>
    <div
      style={{
        backgroundColor: "dimgray",
      }}
    >
      <Layout location={location} title={siteTitle}>
        <SEO title="All posts" />
        <Bio />
        {posts.map(({ node }) => {
              const title = node.frontmatter.title
              const post = node.rawMarkdownBody
          return (
            <article key={node.fields.slug}>
              <header>
                <h3
                  style={{
                    marginBottom: rhythm(1 / 4),
                    color: "lightgray",
                  }}
                >
                  {title}
                </h3>
              </header>
              <section>
                <p
                  style={{
                    color: "lightgray",
                    whiteSpace: "pre-wrap",
                  }}
                  // dangerouslySetInnerHTML={{
                  //   __html: node.frontmatter.description || node.excerpt,
                  // }}
                >
                  {post}
                </p>
              </section>
            </article>
          )
        })}
      </Layout>
    </div>
    // </BackgroundImg>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: ASC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          rawMarkdownBody
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
